import React from 'react';
import ButtonRegular from './buttonRegular';
import techList from './techList';
//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

class Tech extends React.Component {
  constructor(props){
    super(props)
    this.state={
      techList: {}
    }
  }

  componentDidMount(){
    this.setState({techList: techList})
  }

  render() {
    const $bc = "tech";
    return (
      <div id={`${$bc}`} ref={this.props.rRef} className={`${$bc}`}>
        <div className={`${$bc}-header-wrapper section-header-wrapper`}>
            <p>Полный список автопарка</p>
            <h1>Наша техника</h1>
        </div>
        <Swiper id={`${$bc}-swiper`} modules={[EffectCoverflow]} effect="coverflow" slidesPerView={1} rewind={true} navigation pagination={{ clickable: true, dynamicBullets: true }}>
          {
            techList.map((el) => (
              <SwiperSlide key={el.id}>
                <div id={`${$bc}-swiper-wrapper`}>
                  <img src={el.imgRef} alt={`${'Слайд ' + el.id}`}/>
                  <p>От <span>{el.price} ₽</span> в час</p>
                  <h3>{el.name}</h3>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
        <div className={`${$bc}-button-wrapper`}>
          <ButtonRegular
            href="tel:89208074097"
            class="button-call"
            text="Заказать"
            title="Связаться с нами"
            type="call"
          />
        </div>
      </div>
    )
  }
}

export default Tech;