import React from 'react';
import location from '../media/svg/location.svg';
import phone from '../media/svg/phone.svg';
import email from '../media/svg/email.svg';

class Footer extends React.Component {
  render() {
    const $bc = "footer"
    return (
      <div className={`${$bc}`}>
        <div className={`${$bc}-wrapper`}>
          <div className={`${$bc}-wrapper-links-item`}>
            <p className={`${$bc}-wrapper-links-item-header`} ><img src={location} />Адрес</p>
            <p>Москва, Варшавское <br/>шоссе 17</p>
          </div>
          <div className={`${$bc}-wrapper-links-item`}>
            <a className={`${$bc}-wrapper-links-item-header`}  href="tel:89208074097"><img src={phone} />Телефон</a>
            <p>8 (920) 807-40-97</p>
          </div>
          <div className={`${$bc}-wrapper-links-item`}>
            <a className={`${$bc}-wrapper-links-item-header`}  href="mailto:material-group@mail.ru"><img src={email} />e-mail</a>
            <p>material-group@mail.ru</p>
          </div>
        </div>
        <p className={`${$bc}-copyright`}>© ООО «Материал Групп», 2022</p>
        <p className={`${$bc}-special`}>Made with <span>{'<3'}</span> by <a href="https://chepirka.ru">Chepirka</a></p>
      </div>
    )
  }
}

export default Footer