import photo1 from '../media/img/tech/SANY_SY135C.webp';
import photo2 from '../media/img/tech/VOLVO_140D.webp';
import photo3 from '../media/img/tech/CAT_335F.webp';
import photo4 from '../media/img/tech/JCB_210.webp';
import photo5 from '../media/img/tech/JCB_220.webp';
import photo6 from '../media/img/tech/HITACHI_210.webp';
import photo7 from '../media/img/tech/HYUNDAI_210.webp';
import photo8 from '../media/img/tech/JCB_3CX.webp';
import photo9 from '../media/img/tech/BOBCAT.webp';
import photo10 from '../media/img/tech/CAT_D6.webp';
import photo11 from '../media/img/tech/CAT_850K.webp';
import photo12 from '../media/img/tech/КАМАЗ_65115.webp';

const techList = [
    {
        imgRef: photo1,
        name: "Гусеничный экскаватор SANY SY135C",
        price: "2 875",
        id: "1"
    },
    {
        imgRef: photo2,
        name: "Гусеничный экскаватор VOLVO 140D",
        price: "2 750",
        id: "2"
    },
    {
        imgRef: photo3,
        name: "Гусеничный экскаватор CAT 335F",
        price: "3 375",
        id: "3"
    },
    {
        imgRef: photo4,
        name: "Гусеничный экскаватор JCB 210",
        price: "2 875",
        id: "4"
    },
    {
        imgRef: photo5,
        name: "Гусеничный экскаватор JCB 220",
        price: "3 100",
        id: "5"
    },
    {
        imgRef: photo6,
        name: "Гусеничный экскаватор HITACHI 210",
        price: "2 875",
        id: "6"
    },
    {
        imgRef: photo7,
        name: "Колесный экскаватор HYUNDAI 210",
        price: "2 875",
        id: "7"
    },
    {
        imgRef: photo8,
        name: "Экскаватор погрузчик JCB 3CX",
        price: "2 500",
        id: "8"
    },
    {
        imgRef: photo9,
        name: "Мини-погрузчик Bobcat s770",
        price: "2 500",
        id: "9"
    },
    {
        imgRef: photo10,
        name: "Бульдозер CAT D6",
        price: "3 400",
        id: "10"
    },
    {
        imgRef: photo11,
        name: "Бульдозер CAT 850K",
        price: "3 400",
        id: "11"
    },
    {
        imgRef: photo12,
        name: "Самосвал КАМАЗ 65115",
        price: "2 500",
        id: "12"
    }
]
export default techList;