import sputnik1 from '../media/img/projects/sputnik-1.jpg';
import sputnik2 from '../media/img/projects/sputnik-2.jpg';
import rdd1 from '../media/img/projects/rdd-1.jpg';
import rdd2 from '../media/img/projects/rdd-2.jpg';
import rdd3 from '../media/img/projects/rdd-3.jpg';
import ggs1 from '../media/img/projects/ggs-1.jpg';
import ggs2 from '../media/img/projects/ggs-2.jpg';
import ggs3 from '../media/img/projects/ggs-3.jpg';
import ggs4 from '../media/img/projects/ggs-4.jpg';
import ggs5 from '../media/img/projects/ggs-5.jpg';
import ostrov1 from '../media/img/projects/ostrov-1.jpg';
import ostrov2 from '../media/img/projects/ostrov-2.jpg';
import ostrov3 from '../media/img/projects/ostrov-3.jpg';
import ostrov4 from '../media/img/projects/ostrov-4.jpg';
import ostrov5 from '../media/img/projects/ostrov-5.jpg';
import ostrov6 from '../media/img/projects/ostrov-6.jpg';
import ostrov7 from '../media/img/projects/ostrov-7.jpg';
import patriotp1 from '../media/img/projects/patriotp-1.jpg';
import patriotp2 from '../media/img/projects/patriotp-2.jpg';
import patriotp3 from '../media/img/projects/patriotp-3.jpg';
import patriotp4 from '../media/img/projects/patriotp-4.jpg';
import razvilka1 from '../media/img/projects/razvilka-1.jpg';
import razvilka2 from '../media/img/projects/razvilka-2.jpg';
import mbg1 from '../media/img/projects/mbg-1.jpg';
import mbg2 from '../media/img/projects/mbg-2.jpg';
import mbg3 from '../media/img/projects/mbg-3.jpg';
import mbg4 from '../media/img/projects/mbg-4.jpg';
import mbg5 from '../media/img/projects/mbg-5.jpg';
import mbg6 from '../media/img/projects/mbg-6.jpg';
import mbg7 from '../media/img/projects/mbg-7.jpg';
import mbg8 from '../media/img/projects/mbg-8.jpg';
import mbg9 from '../media/img/projects/mbg-9.jpg';
import monino1 from '../media/img/projects/monino-1.jpg';
import monino2 from '../media/img/projects/monino-2.jpg';
import monino3 from '../media/img/projects/monino-3.jpg';
import maryino1 from '../media/img/projects/maryino-1.jpg';
import maryino2 from '../media/img/projects/maryino-2.jpg';
import maryino3 from '../media/img/projects/maryino-3.jpg';
import maryino4 from '../media/img/projects/maryino-4.jpg';
import serdcestolicy from '../media/img/projects/serdce_stolicy.jpg';

const projectList = [
    {
        imgRef: [
            {id:"0101", imgRef: ggs1},
            {id:"0102", imgRef: ggs2},
            {id:"0103", imgRef: ggs3},
            {id:"0104", imgRef: ggs4},
            {id:"0105", imgRef: ggs5}
        ],
        dateM: "Июль ",
        dateY: "2022",
        location: "Сколково",
        name: 'Ремонт дорожного полотна',
        client: 'Сколково Парк',
        id: '1'
    },
    {
        imgRef: [
            {id:"0201", imgRef: rdd1},
            {id:"0202", imgRef: rdd2},
            {id:"0203", imgRef: rdd3}
        ],
        dateM: "Мар.",
        dateY: "2022",
        location: "Ватутинки",
        name: 'Строительство ЖК РАШЕН ДИЗАЙН ДИСТРИКТ',
        client: 'Инновационный кластер',
        id: '2'
    },
    {
        imgRef: [
            {id:"0301", imgRef: serdcestolicy}
        ],
        dateM: "Дек.",
        dateY: "2021",
        location: "Москва",
        name: 'Строительство ЖК Сердце столицы',
        client: 'ДОНСТРОЙ',
        id: '3'
    },
    {
        imgRef: [
            {id:"0401", imgRef: mbg1},
            {id:"0402", imgRef: mbg2},
            {id:"0403", imgRef: mbg3},
            {id:"0404", imgRef: mbg4},
            {id:"0405", imgRef: mbg5},
            {id:"0406", imgRef: mbg6},
            {id:"0407", imgRef: mbg7},
            {id:"0408", imgRef: mbg8},
            {id:"0409", imgRef: mbg9}
        ],
        dateM: "Окт.",
        dateY: "2021",
        location: "мкр-н БЕЛЫЙ ГОРОД",
        name: 'Строительство Микрорайон БЕЛЫЙ ГОРОД',
        client: 'Мерастрой',
        id: '4'
    },
    {
        imgRef: [
            {id:"0501", imgRef: monino1},
            {id:"0502", imgRef: monino2},
            {id:"0503", imgRef: monino3}
        ],
        dateM: "Авг.",
        dateY: "2021",
        location: "пос.Монино",
        name: 'Строительство ЖК Новоград Монино',
        client: 'Аверус',
        id: '5'
    },
    {
        imgRef: [
            {id:"0601", imgRef: patriotp1},
            {id:"0602", imgRef: patriotp2},
            {id:"0603", imgRef: patriotp3},
            {id:"0604", imgRef: patriotp4}
        ],
        dateM: "Июнь ",
        dateY: "2021",
        location: "МО",
        name: 'Ремонт дорожного полотна',
        client: 'Министерство обороны',
        id: '6'
    },
    {
        imgRef: [
            {id:"0701", imgRef: razvilka1},
            {id:"0702", imgRef: razvilka2}
        ],
        dateM: "Июнь ",
        dateY: "2021",
        location: "пос.Развилка",
        name: 'Строительство ЖК Развилка',
        client: 'заказчик ПСТ-Москва',
        id: '7'
    },
    {
        imgRef: [
            {id:"0801", imgRef: maryino1},
            {id:"0802", imgRef: maryino2},
            {id:"0803", imgRef: maryino3},
            {id:"0804", imgRef: maryino4}
        ],
        dateM: "Июнь ",
        dateY: "2021",
        location: "пос.Филимонковское",
        name: 'Строительство ЖК Марьино Град',
        client: 'Capital Group',
        id: '8'
    },
    {
        imgRef: [
            {id:"0901", imgRef: sputnik1},
            {id:"0902", imgRef: sputnik2}
        ],
        dateM: "Май ",
        dateY: "2021",
        location: "д.Раздоры",
        name: 'Строительство ЖК Спутник',
        client: 'Группа «Самолет»',
        id: '9'
    },
    {
        imgRef: [
            {id:"1001", imgRef: ostrov1},
            {id:"1002", imgRef: ostrov2},
            {id:"1003", imgRef: ostrov3},
            {id:"1004", imgRef: ostrov4},
            {id:"1005", imgRef: ostrov5},
            {id:"1006", imgRef: ostrov6},
            {id:"1007", imgRef: ostrov7}
        ],
        dateM: "Янв.",
        dateY: "2021",
        location: "Москва",
        name: 'Строительство ЖК Остров',
        client: 'ДОНСТРОЙ',
        id: '10'
    }
]

export default projectList;