import React from 'react';

class About extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        const $bc = "about"
        return (
            <div ref={this.props.rRef} id={`${$bc}`} className={`${$bc}`}>
                <div className={`${$bc}-header`}>
                    <div className={`${$bc}-header-wrapper section-header-wrapper`}>
                        <p>Кто мы такие</p>
                        <h1>О компании</h1>
                    </div>
                    <p className="section-header-wrapper-sub">ООО «МАТЕРИАЛ-ГРУПП» предоставляет <br/>в аренду спецтехнику различного назначения в Москве. Кроме того, выполняем дорожно-строительные, грунтовые, земляные и уборочные работы любого уровня сложности. Наши заказчики гарантированно получают в свое распоряжение надежное и качественное оборудование, которое в сжатые сроки справится с любой задачей. Такая услуга наиболее актуальна для небольших строительных компаний во время сезона.</p>
                </div>
                <div className={`${$bc}-segments`}>
                    <div className={`${$bc}-segments-item`}>
                        <div className={`${$bc}-segments-item-backdrop`} />
                        <div className={`${$bc}-segments-item-wrapper`}>
                            <h1>2000<i>+</i></h1>
                            <div />
                            <p>Ежегодных <br/>заявок</p>
                        </div>
                    </div>
                    <div className={`${$bc}-segments-item`}>
                        <div className={`${$bc}-segments-item-backdrop`} />
                        <div className={`${$bc}-segments-item-wrapper`}>
                            <h1>86</h1>
                            <div />
                            <p>Постоянных <br/>клиентов</p>
                        </div>    
                    </div>
                    <div className={`${$bc}-segments-item`}>
                        <div className={`${$bc}-segments-item-backdrop`} />
                        <div className={`${$bc}-segments-item-wrapper`}>
                            <h1>14<i>+</i></h1>
                            <div />
                            <p>Лет <br/>работы</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;