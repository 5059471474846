import React from 'react';
import projectList from './projectList';
import location from '../media/svg/location.svg';
import arrow from '../media/svg/arrow.svg';

//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCards, EffectCoverflow } from "swiper";
SwiperCore.use([Navigation, Pagination, EffectCards, EffectCoverflow]);


class Projects extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    const $bc = "projects"
    return (
      <div id={`${$bc}`} ref={this.props.rRef} className={`${$bc}`}>
        <div className={`${$bc}-header-wrapper section-header-wrapper`}>
            <p>Что мы уже сделали</p>
            <h1>Проекты</h1>
        </div>
        <Swiper id={`${$bc}-swiper`} modules={[EffectCoverflow]} rewind={true} effect="coverflow" slidesPerView={1} navigation>
          {
            projectList.map((el) => (
              <SwiperSlide id="projects-swiper-slide" key={el.id}>
                <div id={`${$bc}-swiper-wrapper`}>
                  <Swiper nested={true} id="projects-sub-swiper" modules={[EffectCards]} effect="cards" slidesPerView={1} navigation>
                    {
                      el.imgRef.map((sub) => (
                        <SwiperSlide key={sub.id}>
                          <div className="wrapper">
                            <img src={sub.imgRef} />
                          </div>
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                  <p><img src={location} />{el.location}, {el.dateM}<span>{el.dateY}</span></p>
                  <h3>{el.name}</h3>
                  <div className="line-red" />
                  <h4><span>Заказчик:</span>{el.client}</h4>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    )
  }
}

export default Projects;