import React, { useEffect, createRef, useRef } from 'react';
//STYLESHEETS
import './styles/index.sass';
//COMPONENTS
import NavbarP from './components/navbar';
import NavbarPc from './components/navbarPc';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Tech from './components/Tech';
import Projects from './components/Projects';
import Contacts from './components/Contacts';
import Footer from './components/footer';

function App() {
  //const [isFirstVisible, firstRef] = useVisibility(-100);
  //const [isSecondVisible, secondRef] = useVisibility(-100);
  //const [isThirdVisible, thirdRef] = useVisibility(-100);
  //const [isForthVisible, forthRef] = useVisibility(-100);
  //const [isFifthVisible, fifthRef] = useVisibility(-100);
  const headerRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const techRef = useRef(null);
  const projectsRef = useRef(null);
  const contactsRef = useRef(null);


  
  return (
    <section className="base-body">
      <NavbarP
        headerRef={headerRef}
        aboutRef={aboutRef}
        servicesRef={servicesRef}
        techRef={techRef}
        projectsRef={projectsRef}
        contactsRef={contactsRef}
      />
      <NavbarPc
        headerRef={headerRef}
        aboutRef={aboutRef}
        servicesRef={servicesRef}
        techRef={techRef}
        projectsRef={projectsRef}
        contactsRef={contactsRef}
      />
      <Header rRef={headerRef}/>
      <About rRef={aboutRef} />
      <Services rRef={servicesRef} />
      <Tech rRef={techRef} />
      <Projects rRef={projectsRef} />
      <Contacts rRef={contactsRef} />
      <Footer />
    </section>
  );
}

export default App;
