import React from "react";
import _ from 'lodash';
import telegram from "../media/svg/telegram.svg";
import instagram from "../media/svg/instagram.svg";

class NavbarP extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tglNav: Boolean,
            aboutVisible: false,
            servicesVisible: false,
            techVisible: false,
            projectsVisible: false,
            contactsVisible: false
        }
    }

    componentDidMount() {
        this.setState({
            tglNav: false
        })
        window.addEventListener('scroll', _.throttle(this.listenToHeader, 500));
        window.addEventListener('scroll', _.throttle(this.listenToAbout, 500));
        window.addEventListener('scroll', _.throttle(this.listenToServices, 500));
        window.addEventListener('scroll', _.throttle(this.listenToTech, 500));
        window.addEventListener('scroll', _.throttle(this.listenToProjects, 500));
        window.addEventListener('scroll', _.throttle(this.listenToContacts, 500));
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToHeader);
        window.removeEventListener('scroll', this.listenToAbout);
        window.removeEventListener('scroll', this.listenToServices);
        window.removeEventListener('scroll', this.listenToTech);
        window.removeEventListener('scroll', this.listenToProjects);
        window.removeEventListener('scroll', this.listenToContacts);
    }

    /*scrollToLink = (e, link) =>{
        e.preventDefault();
        this.toggleNavigation();
        window.scrollTo(link)
    }*/

    listenToHeader = () => {
        if(this.props.headerRef){
            if (!this.props.headerRef.current) {
                this.setState({headerVisible: false});
                return;
            }
            const top = this.props.headerRef.current.getBoundingClientRect().top;
            this.setState({headerVisible: top + 100 >= 0 && top - 200 <= window.innerHeight}, () => {if(this.state.headerVisible){this.setState({page: "01"}, () => {return})}});
        }
    }

    listenToAbout = () => {
        if(this.props.aboutRef){
            if (!this.props.aboutRef.current) {
                this.setState({aboutVisible: false});
                return;
            }
            const top = this.props.aboutRef.current.getBoundingClientRect().top;
            this.setState({aboutVisible: top + -100 >= 0 && top - -100 <= window.innerHeight}, () => {if(this.state.aboutVisible){this.setState({page: "02"}, () => {return})}});
        }
    }

    listenToServices = () => {
        if(this.props.servicesRef){
            if (!this.props.servicesRef.current || this.state.aboutVisible || this.state.techVisible || this.state.projectsVisible || this.state.contactsVisible) {
                this.setState({servicesVisible: false});
                return;
            }
            const top = this.props.servicesRef.current.getBoundingClientRect().top;
            this.setState({servicesVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.servicesVisible){this.setState({page: "03"}, () => {return})}});
        }
    }

    listenToTech = () => {
        if(this.props.techRef){
            if (!this.props.techRef.current || this.state.aboutVisible || this.state.servicesVisible || this.state.projectsVisible || this.state.contactsVisible) {
                this.setState({techVisible: false});
                return;
            }
            const top = this.props.techRef.current.getBoundingClientRect().top;
            this.setState({techVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.techVisible){this.setState({page: "04"}, () => {return})}});
        }
    }

    listenToProjects = () => {
        if(this.props.projectsRef){
            if (!this.props.projectsRef.current || this.state.aboutVisible || this.state.techVisible || this.state.servicesVisible || this.state.contactsVisible) {
                this.setState({servicesVisible: false});
                return;
            }
            const top = this.props.projectsRef.current.getBoundingClientRect().top;
            this.setState({projectsVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.projectsVisible){this.setState({page: "05"}, () => {return})}});
        }
    }

    listenToContacts = () => {
        if(this.props.contactsRef){
            if (!this.props.contactsRef.current || this.state.aboutVisible || this.state.techVisible || this.state.projectsVisible || this.state.servicesVisible) {
                this.setState({contactsVisible: false});
                return;
            }
            const top = this.props.contactsRef.current.getBoundingClientRect().top;
            this.setState({contactsVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.contactsVisible){this.setState({page: "06"}, () => {return})}});
        }
    }

    toggleNavigation = () => {
        {this.setState({tglNav: !this.state.tglNav}, () => 
                    {
                        switch(this.state.tglNav){
                            case true:
                                document.getElementsByTagName("body")[0].style.overflowY = "hidden";
                                break;
                            case false:
                                document.getElementsByTagName("body")[0].style.overflowY = "auto";
                                break;
                        }
                    }
                )}
    }

    render(){
        const $bc = "navbar-container-primary";
        return(
            <div className={`${$bc}`}>
                <div className={`${$bc}-logo`}><a title="Перейти к началу" href="#header"><i>Material</i><i>Group</i></a></div>
                <div onClick={this.toggleNavigation} className={`${$bc}-burger` + `${this.state.tglNav? ' toggled' : ''}`}><div className={`${$bc}-burger-sub`}/></div>
                <div className={`${$bc}-contact`}><a className="contact-phone" title="Горячая линия" href="tel:89208074097">8 (920)<i> 807-40-97</i></a></div>
                <div className={`${$bc}-dropdown` + `${this.state.tglNav? ' toggled' : ''}`}>
                    <div className={`${$bc}-dropdown-wrapper`}>
                        <div className={`${$bc}-dropdown-dummy`} />
                        <ul>
                            <li><a className={`${this.state.aboutVisible? "active" : ""}`} href="#about" title="Узнать о нас">О компании</a></li>
                            <li><a className={`${this.state.servicesVisible? "active" : ""}`} href="#services" title="Подробней о наших услугах">Услуги</a></li>
                            <li><a className={`${this.state.techVisible? "active" : ""}`} href="#tech" title="Полный список автопарка">Техника</a></li>
                            <li><a className={`${this.state.projectsVisible? "active" : ""}`} href="#projects" title="Над чем мы работаем">Проекты</a></li>
                            <li><a className={`${this.state.contactsVisible? "active" : ""}`} href="#contacts" title="Как связаться с нами">Контакты</a></li>
                        </ul>
                        <div className={`${$bc}-dropdown-contacts`}>
                            <div className={`${$bc}-dropdown-contact-phone-wrapper`}><a className="contact-phone" title="Горячая линия" href="tel:89208074097">8 (920)<i> 807-40-97</i></a></div>
                            <div>
                                <span><img src={telegram} alt="Иконка" /><a href="https://t.me/MaterialGroupp">Telegram</a></span>
                                <span><img src={instagram} alt="Иконка" /><a href="">Instagram</a></span>
                                <p>MaterialGroup</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavbarP;