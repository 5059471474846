import React from 'react';
import telegram from '../media/svg/telegram.svg';
import instagram from '../media/svg/instagram.svg';
import _ from'lodash';

export default class NavbarPc extends React.Component {
    constructor(props){
        super(props);
        this.state={
            aboutVisible: false,
            servicesVisible: false,
            techVisible: false,
            projectsVisible: false,
            contactsVisible: false,
            page: "01",
            isContactsHidden: false
        }
    }
    
    componentDidMount() {
        this.setState({isContactsVisible: false});
        window.addEventListener('scroll', _.throttle(this.listenToHeader, 500));
        window.addEventListener('scroll', _.throttle(this.listenToAbout, 500));
        window.addEventListener('scroll', _.throttle(this.listenToServices, 500));
        window.addEventListener('scroll', _.throttle(this.listenToTech, 500));
        window.addEventListener('scroll', _.throttle(this.listenToProjects, 500));
        window.addEventListener('scroll', _.throttle(this.listenToContacts, 500));
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToHeader);
        window.removeEventListener('scroll', this.listenToAbout);
        window.removeEventListener('scroll', this.listenToServices);
        window.removeEventListener('scroll', this.listenToTech);
        window.removeEventListener('scroll', this.listenToProjects);
        window.removeEventListener('scroll', this.listenToContacts);
    }

    listenToHeader = () => {
        if(this.props.headerRef){
            if (!this.props.headerRef.current) {
                this.setState({headerVisible: false});
                return;
            }
            const top = this.props.headerRef.current.getBoundingClientRect().top;
            this.setState({headerVisible: top + 100 >= 0 && top - 200 <= window.innerHeight}, () => {if(this.state.headerVisible){this.setState({page: "01"}, () => {return})}});
        }
    }

    listenToAbout = () => {
        if(this.props.aboutRef){
            if (!this.props.aboutRef.current) {
                this.setState({aboutVisible: false});
                return;
            }
            const top = this.props.aboutRef.current.getBoundingClientRect().top;
            this.setState({aboutVisible: top + -100 >= 0 && top - -100 <= window.innerHeight}, () => {if(this.state.aboutVisible){this.setState({page: "02"}, () => {return})}});
        }
    }

    listenToServices = () => {
        if(this.props.servicesRef){
            if (!this.props.servicesRef.current || this.state.aboutVisible || this.state.techVisible || this.state.projectsVisible || this.state.contactsVisible) {
                this.setState({servicesVisible: false});
                return;
            }
            const top = this.props.servicesRef.current.getBoundingClientRect().top;
            this.setState({servicesVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.servicesVisible){this.setState({page: "03"}, () => {return})}});
        }
    }

    listenToTech = () => {
        if(this.props.techRef){
            if (!this.props.techRef.current || this.state.aboutVisible || this.state.servicesVisible || this.state.projectsVisible || this.state.contactsVisible) {
                this.setState({techVisible: false});
                return;
            }
            const top = this.props.techRef.current.getBoundingClientRect().top;
            this.setState({techVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.techVisible){this.setState({page: "04"}, () => {return})}});
        }
    }

    listenToProjects = () => {
        if(this.props.projectsRef){
            if (!this.props.projectsRef.current || this.state.aboutVisible || this.state.techVisible || this.state.servicesVisible || this.state.contactsVisible) {
                this.setState({servicesVisible: false});
                return;
            }
            const top = this.props.projectsRef.current.getBoundingClientRect().top;
            this.setState({projectsVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.projectsVisible){this.setState({page: "05"}, () => {return})}});
        }
    }

    listenToContacts = () => {
        if(this.props.contactsRef){
            if (!this.props.contactsRef.current || this.state.aboutVisible || this.state.techVisible || this.state.projectsVisible || this.state.servicesVisible) {
                this.setState({contactsVisible: false});
                return;
            }
            const top = this.props.contactsRef.current.getBoundingClientRect().top;
            this.setState({contactsVisible: top + 200 >= 0 && top - -20 <= window.innerHeight}, () => {if(this.state.contactsVisible){this.setState({page: "06"}, () => {return})}});
        }
    }

    render() {
        const $bc = "navbar-pc-interface"
        return (
            <div>
                <div className={`${$bc}-left`}>
                    <div className={`${$bc}-left-counter`}><span>{this.state.page}</span> / 06</div>
                </div>
                <div className={`${$bc}-right`}>
                    <nav id="navbar" className={`${$bc}-right-scroll-hrefs`}>
                        <ul>
                            <li className={`${this.state.aboutVisible? 'active' : ''}`}><a href="#about" title="Узнать о нас">О компании</a><div></div></li>
                            <li className={`${this.state.servicesVisible? 'active' : ''}`}><a href="#services" title="Подробней о наших услугах">Услуги</a><div></div></li>
                            <li className={`${this.state.techVisible? 'active' : ''}`}><a href="#tech" title="Полный список автопарка">Техника</a><div></div></li>
                            <li className={`${this.state.projectsVisible? 'active' : ''}`}><a href="#projects" title="Над чем мы работаем">Проекты</a><div></div></li>
                            <li className={`${this.state.contactsVisible? 'active' : ''}`}><a href="#contacts" title="Как связаться с нами">Контакты</a><div></div></li>
                        </ul>
                        <div className={`${$bc}-right-scroll-hrefs-line`}></div>
                    </nav>
                </div>
                <div className={`${$bc}-right-corner-contacts ${this.state.isContactsHidden? "hidden" : ''}`}>
                    <div title="Переключить видимость" onClick={() => {this.setState({isContactsHidden: !this.state.isContactsHidden}, () => {return})}} />
                    <span><a href="https://t.me/MaterialGroupp"><img src={telegram} alt="Иконка" />Telegram</a></span>
                    <span><a href=""><img src={instagram} alt="Иконка" />Instagram</a></span>
                </div>
            </div>
        )
    }
}
