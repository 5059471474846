import React from 'react';
import ButtonRegular from './buttonRegular';

class Services extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    const $bc = "services"
    return (
      <div ref={this.props.rRef} id={`${$bc}`} className={`${$bc}`}>
        <div className={`${$bc}-header`}>
            <div className={`${$bc}-header-item`}>
              <div className={`${$bc}-header-item-wrapper section-header-wrapper`}>
                <p className={`${$bc}-header-item-wrapper-caption`}>Что мы можем</p>
                <h1>Услуги</h1>
              </div>
              <p className={`${$bc}-header-item-wrapper-sub section-header-wrapper-sub`}>Мы предоставляем в аренду любую строительную технику и осуществляем комплексное снабжение строительных площадок Москвы и Московоской области.</p>
              <div className={`${$bc}-header-item-wrapper-button-wrapper`}>
                <ButtonRegular
                    href="tel:89208074097"
                    class={`${$bc}-header-item-wrapper-button button-call`}
                    text="Связаться с нами"
                />
              </div>
            </div>
        </div>
        <div className={`${$bc}-list`}>
            <div className={`${$bc}-list-item`}>
                <h2>Песок</h2>
                <div></div>
                <ul>
                    <li>Песок карьерный от 650  ₽ за куб.м.</li>
                    <li>Песок речной от 1000  ₽ за куб.м.</li>
                    <li>Песок сеяный от 800  ₽ за куб.м.</li>
                </ul>
            </div>
            <div className={`${$bc}-list-item`}>
                <h2>Щебень</h2>
                <div></div>
                <ul>
                    <li>Щебень гранитный от 2600  ₽ за тонну</li>
                    <li>Щебень гравийный от 2200  ₽ за тонну</li>
                    <li>Щебень известняковый от 1800  ₽ за тонну</li>
                </ul>
            </div>
        </div>
      </div>
    )
  }
}

export default Services;