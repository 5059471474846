import React from 'react';
import ButtonRegular from './buttonRegular';

class Header extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    render(){
        const $bc = "header"
        return(
            <div ref={this.props.rRef} id={`${$bc}`} className={`${$bc}`}>
                <h1>Широкий спектр услуг<br/>по строительным<br/>работам</h1>
                <div className={`${$bc}-capture`}>
                    <span>
                        Предоставляем <i>отличные скидки</i><br/>
                        В зависимости от <i>объёма работ!</i>
                    </span>
                </div>
                <div className={`${$bc}-special`}>
                    <h2>От 360 ₽ за кв.м.</h2>
                    <div></div>
                    <p>Все дорожные<br/>работы в Москве</p>
                </div>
                <div className={`${$bc}-button-wrapper`}>
                    <ButtonRegular
                        href="#about"
                        title="Далее"
                        class={`${$bc}-button-wrapper-button button-more`}
                        text="Узнать больше"
                        type="more"
                    />
                    <a href="#tech" title="Наш автопарк" className={`${$bc}-button-wrapper-button button-tech`}>Перейти к технике</a>
                </div>
            </div>
        )
    }
}

export default Header;