import React from 'react';

const arrow = <svg width="25" height="8" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z" fill="white"/>
              </svg>

const phone = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1117_308)">
                  <path d="M4.965 8.0925C6.045 10.215 7.785 11.9475 9.9075 13.035L11.5575 11.385C11.76 11.1825 12.06 11.115 12.3225 11.205C13.1625 11.4825 14.07 11.6325 15 11.6325C15.4125 11.6325 15.75 11.97 15.75 12.3825V15C15.75 15.4125 15.4125 15.75 15 15.75C7.9575 15.75 2.25 10.0425 2.25 3C2.25 2.5875 2.5875 2.25 3 2.25H5.625C6.0375 2.25 6.375 2.5875 6.375 3C6.375 3.9375 6.525 4.8375 6.8025 5.6775C6.885 5.94 6.825 6.2325 6.615 6.4425L4.965 8.0925Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1117_308">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

export default class ButtonRegular extends React.Component {
  constructor(props){
    super(props)
  }

  iconHandler(){
  }

  render() {
    return (
      <a href={this.props.href} title={this.props.title} className={`${this.props.class} button-regular`}>{this.props.text}
        <div className="button-container">
          <div className="button-outline-before"></div>
          <div className="button-outline-after"></div>
            {this.props.type === 'more'? arrow : phone}
        </div></a>
    )
  }
}
