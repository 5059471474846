import React from 'react';
import axios from 'axios';
import location from '../media/svg/location.svg';
import phone from '../media/svg/phone.svg';
import email from '../media/svg/email.svg';
import _ from 'lodash';

const arrow = <svg width="25" height="8" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z" fill="white"/>
              </svg>

const regex = /^(?=.{15}$)\d+(?: +\d+)*$/gmu;

class Contacts extends React.Component {
  constructor(props){
    super(props);
    this.state = { fields: {"name": "", "phone": ""}, errors: {"name": "", "phone": ""}}
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Заполните поле (от 2 до 18 символов)";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[А-Яа-яЁё]+$/)) {
        formIsValid = false;
        errors["name"] = "Только Кирилица";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Заполните поле в формате 8 123 456 78 91 без лишних символов";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["phone"].match(regex) || fields["phone"].length > 15) {
        formIsValid = false;
        errors["phone"] = "Неверный номер телефона"
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
      formData.append('name', this.state.fields["name"])
      formData.append('phone', _.cloneDeep(this.state.fields["phone"]).replace(/\s/g, ""))
    if (this.handleValidation()) {
      axios.post('entry.php', formData)
                  .then(function (response) {
                    //handle success
                    alert("Форма отправлена!")
                  })
                  .catch(function (response) {
                    //handle error
                    alert("Произошла ошибка при отправки формы");
                  });
    } else {
      alert("Проверьте, правильно ли заполнены все поля");
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    if(field === "phone"){
        let val = e.target.value;
        let value = val.replace(/ /gm, '');
        let num = `${value.substring(0, 1)} ${value.substring(1, 4)} ${value.substring(4, 7)} ${value.substring(7, 9)} ${value.substring(9, 11)}`;
        num = num.replace(/^./g, '8');
        num = num.trim();
        fields[field] = num;
    }

    else{
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  }
  

  render() {
    const $bc = "contacts"
    return (
      <div id={`${$bc}`} ref={this.props.rRef} className={`${$bc}`}>
        <div className={`${$bc}-header`}>
          <div className={`${$bc}-header-wrapper section-header-wrapper`}>
            <p>Связаться с нами</p>
            <h1>Контакты</h1>
          </div>
          <div className={`${$bc}-header-links`}>
            <div className={`${$bc}-header-links-item`}>
              <p className={`${$bc}-header-links-item-header`} ><img src={location} />Адрес</p>
              <p>Москва, Варшавское <br/>шоссе 17</p>
            </div>
            <div className={`${$bc}-header-links-item`}>
              <a className={`${$bc}-header-links-item-header`}  href="tel:89208074097"><img src={phone} />Телефон</a>
              <p>8 (920) 807-40-97</p>
            </div>
            <div className={`${$bc}-header-links-item`}>
              <a className={`${$bc}-header-links-item-header`}  href="mailto:material-group@mail.ru"><img src={email} />e-mail</a>
              <p>material-group@mail.ru</p>
            </div>
          </div>
        </div>
        <div className={`${$bc}-form-wrapper`}>
          <div className={`${$bc}-form-wrapper-header`}>

          </div>
          <form onSubmit={this.contactSubmit.bind(this)} className={`${$bc}-form-wrapper-form`}>
            <div className={`${$bc}-form-wrapper-form-input`}>
              <input
                onChange={this.handleChange.bind(this, "name")}
                ref="name"
                name="name"
                type="text"
                value={this.state.fields["name"]}
                required
              />
              <label className={`${$bc}-form-wrapper-form-input-label`} htmlFor="name">Имя <span style={{'color' : '#D4282F'}}> {this.state.errors["name"]}</span></label>
            </div>
            <div className={`${$bc}-form-wrapper-form-input`}>
              <input 
                onChange={this.handleChange.bind(this, "phone")}
                ref="phone"
                name="phone"
                type="text"
                value={this.state.fields["phone"]}
                required
              />
              <label className={`${$bc}-form-wrapper-form-input-label`} htmlFor="phone">Телефон <span style={{'color' : '#D4282F'}}> {this.state.errors["phone"]}</span></label>
            </div>
            <button type="submit" className="button-more button-regular">Отправить
              <div className="button-container">
                <div className="button-outline-before" />
                <div className="button-outline-after" />
                {arrow}
              </div>
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default Contacts;